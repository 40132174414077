/* AG Grid Style Overrides */
body {
  --eclipse-tab-header-color: color-mix(in srgb, #fff, #0b1d28 93%);
  --eclipse-tab-border-color: rgba(255, 255, 255, 0.16);
}

.ag-theme-quartz-dark.borderless {
  --ag-wrapper-border-radius: 0;
  .ag-root-wrapper {
    border: none;
    border-radius: 0;
  }
}

.datagrid-top-menu-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  background-color: color-mix(in srgb, #fff, #182230 93%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); */
  z-index: 300;
}

.eclipse-presence {
  box-shadow: inset 0px 0px 3px 0px #15aabf52;
  background-color: #15aabf14;
}

.fxtr-def-inherit {
  font-style: italic;
  color: rgb(182, 182, 182);
}

.ag-cell-inline-editing.fxtr-def-inherit {
  font-style: normal;
  color: var(--ag-data-color);
}

.fxtr-def-override {
  font-style: normal;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.fxtr-addr-invalid {
  color: red;
}

.ag-cell-inline-editing.fxtr-addr-invalid {
  color: var(--ag-data-color);
}

.eclipse-fixnum-emphasis {
  text-align: center;
  font-weight: 100;
  font-size: 18px;
}

/* If focused and has .eclipse-numeric-parser, add an icon to the right side */
.ag-cell-focus.eclipse-numeric-parser::after {
  content: '#';
  font-weight: 400;
  font-size: 16px;
  color: var(--mantine-color-primary);
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

/* Borderless */
.ag-no-border .ag-root-wrapper {
  border: none;
  border-radius: 0;
}

/* Styles to hide grid for print preview */
.invisible-grid-wrapper .ag-root {
  display: none;
}
