.eclipse-page-editor-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.eclipse-page-editor-left {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 20%;
  background-color: var(--mantine-color-body);
  border-right: 1px solid var(--mantine-color-default-border);
  overflow: hidden;
}

.eclipse-page-editor-right {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--mantine-color-body);
  overflow: hidden;
}
