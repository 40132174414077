/* EDITOR STYLES */

.autocomplete-menu-item {
  height: 24px;
  padding-left: 18px;
  line-height: 24px;
}

.autocomplete-menu {
  margin-top: 1px;
  padding: 0;
  list-style: none;
  max-height: 300px;
  overflow: auto;
  background-color: #222628;
  border: 1px solid #68686e;
  border-top: none;
  border-radius: 3px;
  /* box-shadow: 0 1px 20px 1px black; */
}

.combobox-wrapper {
  box-shadow: 0 2px 0.5px rgba(255, 255, 255, 0.5), 00 0 4px 3px rgba(33, 150, 243, 0.6);
  border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
  border-radius: 3px;
  background-color: #151515;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  white-space: normal;
  position: relative;
  padding-right: 20px;

  .void-button {
    position: absolute;
    right: 0;
    margin: 4px;
    color: rgb(80, 80, 80);
    cursor: pointer;
  }
  .void-button:hover {
    color: rgb(255, 255, 255);
  }
  .void-button:focus {
    color: rgb(255, 255, 255);
    box-shadow: 0 0 4px 3px rgba(33, 150, 243, 0.6);
  }

  input {
    width: 64px;
    height: 24px;
    padding: 0;
    margin: 0px 4px;
  }

  .multi-menu-toggle {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 10V8h-2v4H8l4 4 4-4h-3z' fill='rgba(80,80,80,1)'/%3E%3C/svg%3E");
    width: 16px;
    height: 16px;
    background-color: transparent;
    transition: all 0.4s;
  }
  .multi-menu-toggle:hover {
    filter: brightness(2);
    transition: all 0.2s;
  }
}

.multi-autocomplete-menu {
  padding: 0;
  list-style: none;
  max-height: 300px;
  overflow: auto;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: calc(100% + 2px);
}

.multi-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 40px;
}

.multi-item-entry {
  padding: 2px 6px;
  margin: 4px 2px;
  border: 1px solid rgb(80, 80, 80);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  height: 20px;
}
.multi-item-remove {
  display: inline-block;
  margin-left: 4px;
  text-align: center;
  color: rgb(80, 80, 80);
  cursor: pointer;
  transition: all 0.4 s;
}
.multi-item-remove:hover {
  color: white;
  transition: all 0.2s;
}
.multi-item-entry:focus {
  box-shadow: 0 0 4px 3px rgba(33, 150, 243, 0.6);
  border-color: var(--ag-input-focus-border-color, rgb(130, 199, 255));
  outline: none;
}
