* {
  font-family: 'Open Sans', sans-serif;
  --mantine-color-body: #13141d;
  --_popover-bg: #1f2029;
}
body {
  background-color: #13141d;
}
.highlight-row {
  background-color: '#364d60';
  transition: 'background-color 0s ease-out';
}
.highlight-row.animate {
  background-color: '#364d6000';
  transition: 'background-color 2s ease-out';
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
}
