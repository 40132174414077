.eclipse-page-preview-outer-container {
  height: 100%;
  width: 100%;
  background-color: var(--mantine-color-body);
  overflow: hidden;
}

.eclipse-page-preview-inner-container {
  height: 100%;
  width: 100%;
  background-color: var(--mantine-color-body);
}

.previewgrid-top-menu-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  background-color: #222628;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 300;
}

.eclipse-page-preview-simulated-page {
  width: 8.5in;
  height: 11in;
  margin: 32px auto;
  padding: 0.5in;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border: 1px solid var(--mantine-color-default-border);
  box-shadow: -1px 10px 20px 10px rgb(12, 12, 12);
}

.eclipse-page-preview-margin {
  width: 100%;
  height: 100%;
  /* border: 1px solid #373a4017;
  box-shadow: inset 0px 0px 20px 0px #4848480a; */
  /* border-radius: 3px; */
  overflow: hidden;
}

.eclipse-preview-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.preview-floating-zoom-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.5rem;
  z-index: 400;
}
