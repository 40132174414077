/* Define a variable called mantine-header-height */
:root {
  --mantine-header-height: 48px;
}

/* Add dropdown indicator to action buttons */
.action-with-dropdown::after {
  content: '';
  position: absolute;
  top: calc(100% + 0.5px);
  left: 50%;
  transform: translateX(-50%);
  border: solid transparent;
  border-top-color: var(--_ai-bg, var(--ai-bg, var(--mantine-primary-color-filled)));
  border-width: 5px;
  z-index: 1;
}

/* Add + indicator to top left of action buttons */
.action-with-plus::before {
  content: '+';
  position: absolute;
  top: 3px;
  left: 2px;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 700;
  color: var(--mantine-color-text);
  z-index: 1;
}
button.action-with-plus {
  overflow: visible;
}
