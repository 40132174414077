.flexlayout__layout {
  --color-text: #eeeeee;
  --color-background: 13141D;
  --color-base: black;
  --color-1: #121212;
  --color-2: #13141d;
  --color-3: #262626;
  --color-4: #333333;
  --color-5: #404040;
  --color-6: #4d4d4d;
  --color-drag1: rgb(207, 232, 255);
  --color-drag2: rgb(183, 209, 181);
  --color-drag1-background: rgba(128, 128, 128, 0.15);
  --color-drag2-background: rgba(128, 128, 128, 0.15);
  --font-size: medium;
  --font-family: Open Sans, Arial, sans-serif;
  --color-overflow: gray;
  --color-icon: gray;
  --color-tabset-background: color-mix(in srgb, #fff, #182230 93%);
  --color-tabset-background-selected: var(--color-1);
  --color-tabset-background-maximized: var(--color-6);
  --color-tabset-divider-line: var(--color-4);
  --color-tabset-header-background: var(--color-1);
  --color-tabset-header: var(--color-text);
  --color-border-background: var(--color-1);
  --color-border-divider-line: var(--color-4);
  --color-tab-selected: var(--color-text);
  --color-tab-selected-background: var(--color-4);
  --color-tab-unselected: #b4b8bd;
  --color-tab-unselected-background: transparent;
  --color-tab-textbox: var(--color-text);
  --color-tab-textbox-background: var(--color-3);
  --color-border-tab-selected: var(--color-text);
  --color-border-tab-selected-background: var(--color-4);
  --color-border-tab-unselected: gray;
  --color-border-tab-unselected-background: var(--color-2);
  --color-splitter: var(--color-2);
  --color-splitter-hover: var(--color-4);
  --color-splitter-drag: var(--color-5);
  --color-drag-rect-border: var(--color-4);
  --color-drag-rect-background: var(--color-1);
  --color-drag-rect: var(--color-text);
  --color-popup-border: var(--color-6);
  --color-popup-unselected: var(--color-text);
  --color-popup-unselected-background: black;
  --color-popup-selected: var(--color-text);
  --color-popup-selected-background: var(--color-4);
  --color-edge-marker: gray;
  --eclipse-tab-margin: 0.5em;
  --eclipse-header-height: 3rem;
  --eclipse-footer-height: 2rem;
  --eclipse-tabset-border-radius: 0.8em;
  --eclipse-tab-border-color: hsl(210, 25%, 30%);
}

.flexlayout__layout {
  left: var(--eclipse-tab-margin);
  top: calc(var(--eclipse-header-height) + var(--eclipse-tab-margin));
  right: var(--eclipse-tab-margin);
  bottom: var(--eclipse-footer-height);
  position: absolute;
  overflow: hidden;
}
.flexlayout__splitter {
  background-color: var(--color-splitter);
}
@media (hover: hover) {
  .flexlayout__splitter:hover {
    background-color: var(--color-splitter-hover);
    transition: background-color ease-in 0.1s;
    transition-delay: 0.05s;
  }
}
.flexlayout__splitter_border {
  z-index: 10;
}
.flexlayout__splitter_drag {
  z-index: 1000;
  background-color: var(--color-splitter-drag);
  border-radius: 5px;
}
.flexlayout__splitter_extra {
  background-color: transparent;
}
.flexlayout__outline_rect {
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  border: 2px solid var(--color-drag1);
  background: var(--color-drag1-background);
  border-radius: 5px;
  z-index: 1000;
}
.flexlayout__outline_rect_edge {
  pointer-events: none;
  border: 2px solid var(--color-drag2);
  background: var(--color-drag2-background);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
}
.flexlayout__edge_rect {
  position: absolute;
  z-index: 1000;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: var(--color-edge-marker);
  pointer-events: none;
}
.flexlayout__drag_rect {
  position: absolute;
  cursor: move;
  color: var(--color-drag-rect);
  background-color: var(--color-drag-rect-background);
  border: 2px solid var(--color-drag-rect-border);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
  opacity: 0.9;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 0.3em 1em;
  word-wrap: break-word;
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__tabset {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  font-size: var(--font-size);
  font-family: var(--font-family);
  border-radius: var(--eclipse-tabset-border-radius) var(--eclipse-tabset-border-radius) 0 0;
}
.flexlayout__tabset_tab_divider {
  width: 4px;
}
.flexlayout__tabset_content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 var(--eclipse-tabset-border-radius) var(--eclipse-tabset-border-radius);
  /* border-left: 1px solid var(--eclipse-tab-border-color);
  border-right: 1px solid var(--eclipse-tab-border-color);
  border-bottom: 1px solid var(--eclipse-tab-border-color); */
}
.flexlayout__tabset_header {
  display: flex;
  align-items: center;
  padding: 3px 3px 3px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-tabset-divider-line);
  color: var(--color-tabset-header);
  background-color: var(--color-tabset-header-background);
  box-shadow: inset 0 0 3px 0 rgba(136, 136, 136, 0.54);
}
.flexlayout__tabset_header_content {
  flex-grow: 1;
}
.flexlayout__tabset_tabbar_outer {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  border-top: 1px solid var(--eclipse-tab-border-color);
  border-left: 1px solid var(--eclipse-tab-border-color);
  border-right: 1px solid var(--eclipse-tab-border-color);
  border-bottom: none;
  border-radius: var(--eclipse-tabset-border-radius) var(--eclipse-tabset-border-radius) 0 0;
  background-color: color-mix(in srgb, #fff, #16181a 100%);
}

.flexlayout__tabset_tabbar_outer_bottom {
  border-top: 1px solid var(--color-tabset-divider-line);
}
.flexlayout__tabset_tabbar_inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.flexlayout__tabset_tabbar_inner_tab_container {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10000px;
}

.flexlayout__tabset_tabbar_inner_tab_container_bottom {
  border-bottom: 2px solid transparent;
}
.flexlayout__tabset-selected {
  background-color: color-mix(in srgb, #fff, #182230 97%);
}
.flexlayout__tabset-maximized {
  background-color: var(--color-tabset-background-maximized);
  background-image: linear-gradient(var(--color-6), var(--color-2));
}
.flexlayout__tab_button_stamp {
  display: inline-flex;
  align-items: center;
  gap: 0.3em;
  white-space: nowrap;
  box-sizing: border-box;
}
.flexlayout__tab {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: var(--color-text);
  border-radius: 0 0 var(--eclipse-tabset-border-radius) var(--eclipse-tabset-border-radius);
  border-right: 1px solid var(--eclipse-tab-border-color);
  border-bottom: 1px solid var(--eclipse-tab-border-color);
  border-left: 1px solid var(--eclipse-tab-border-color);
}
.flexlayout__tab_button {
  display: flex;
  gap: 0.3em;
  align-items: center;
  box-sizing: border-box;
  padding: 3px 0.5em;
  cursor: pointer;
}
.flexlayout__tab_button--selected {
  background-color: color-mix(in srgb, #fff, #182230 93%);
  color: var(--color-tab-selected);
}
@media (hover: hover) {
  .flexlayout__tab_button:hover {
    /* background-color: var(--color-tab-selected-background); */
    color: var(--color-tab-selected);
    background: linear-gradient(180deg, #384558, #28313e);
  }
}
.flexlayout__tab_button--unselected {
  background-color: var(--color-tab-unselected-background);
  color: var(--color-tab-unselected);
}
.flexlayout__tab_button_top {
  border-top-left-radius: var(--eclipse-tabset-border-radius);
  border-top-right-radius: var(--eclipse-tabset-border-radius);
}
.flexlayout__tab_button_bottom {
  box-shadow: inset -2px 0px 5px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.flexlayout__tab_button_leading {
  display: flex;
}
.flexlayout__tab_button_content {
  display: flex;
}
.flexlayout__tab_button_textbox {
  border: none;
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--color-tab-textbox);
  background-color: var(--color-tab-textbox-background);
  border: 1px inset var(--color-1);
  border-radius: 3px;
  width: 10em;
}
.flexlayout__tab_button_textbox:focus {
  outline: none;
}
.flexlayout__tab_button_trailing {
  display: flex;
  visibility: hidden;
  border-radius: 4px;
}
.flexlayout__tab_button_trailing:hover {
  background-color: var(--color-6);
}
@media (hover: hover) {
  .flexlayout__tab_button:hover .flexlayout__tab_button_trailing {
    visibility: visible;
  }
}
.flexlayout__tab_button--selected .flexlayout__tab_button_trailing {
  visibility: visible;
}
.flexlayout__tab_button_overflow {
  display: flex;
  align-items: center;
  border: none;
  color: var(--color-overflow);
  font-size: inherit;
  background-color: transparent;
}
.flexlayout__tab_toolbar {
  display: flex;
  align-items: center;
  gap: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.3em;
}
.flexlayout__tab_toolbar_button {
  border: none;
  outline: none;
  font-size: inherit;
  margin: 0px;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;
}
@media (hover: hover) {
  .flexlayout__tab_toolbar_button:hover {
    background-color: var(--color-4);
  }
}
.flexlayout__tab_toolbar_sticky_buttons_container {
  display: flex;
  gap: 0.3em;
  padding-left: 5px;
  align-items: center;
}
.flexlayout__tab_floating {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: var(--color-text);
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexlayout__tab_floating_inner {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flexlayout__tab_floating_inner div {
  margin-bottom: 5px;
  text-align: center;
}
.flexlayout__tab_floating_inner div a {
  color: royalblue;
}
.flexlayout__border {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--color-border);
  background-color: var(--color-border-background);
}
.flexlayout__border_top {
  border-bottom: 1px solid var(--color-border-divider-line);
  align-items: center;
}
.flexlayout__border_bottom {
  border-top: 1px solid var(--color-border-divider-line);
  align-items: center;
}
.flexlayout__border_left {
  border-right: 1px solid var(--color-border-divider-line);
  align-content: center;
  flex-direction: column;
}
.flexlayout__border_right {
  border-left: 1px solid var(--color-border-divider-line);
  align-content: center;
  flex-direction: column;
}
.flexlayout__border_inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
}
.flexlayout__border_inner_tab_container {
  white-space: nowrap;
  display: flex;
  padding-left: 2px;
  padding-right: 2px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10000px;
}
.flexlayout__border_inner_tab_container_right {
  transform-origin: top left;
  transform: rotate(90deg);
}
.flexlayout__border_inner_tab_container_left {
  flex-direction: row-reverse;
  transform-origin: top right;
  transform: rotate(-90deg);
}
.flexlayout__border_tab_divider {
  width: 4px;
}
.flexlayout__border_button {
  display: flex;
  gap: 0.3em;
  align-items: center;
  cursor: pointer;
  padding: 3px 0.5em;
  margin: 2px 0px;
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.flexlayout__border_button--selected {
  background-color: var(--color-border-tab-selected-background);
  color: var(--color-border-tab-selected);
}
@media (hover: hover) {
  .flexlayout__border_button:hover {
    background-color: var(--color-border-tab-selected-background);
    color: var(--color-border-tab-selected);
    background-color: var(--color-4);
  }
}
.flexlayout__border_button--unselected {
  background-color: var(--color-border-tab-unselected-background);
  color: var(--color-border-tab-unselected);
}
.flexlayout__border_button_leading {
  display: flex;
}
.flexlayout__border_button_content {
  display: flex;
}
.flexlayout__border_button_trailing {
  display: flex;
  border-radius: 4px;
  visibility: hidden;
}
.flexlayout__border_button_trailing:hover {
  background-color: var(--color-6);
}
@media (hover: hover) {
  .flexlayout__border_button:hover .flexlayout__border_button_trailing {
    visibility: visible;
  }
}
.flexlayout__border_button--selected .flexlayout__border_button_trailing {
  visibility: visible;
}
.flexlayout__border_toolbar {
  display: flex;
  gap: 0.3em;
  align-items: center;
}
.flexlayout__border_toolbar_left,
.flexlayout__border_toolbar_right {
  flex-direction: column;
  padding-top: 0.5em;
  padding-bottom: 0.3em;
}
.flexlayout__border_toolbar_top,
.flexlayout__border_toolbar_bottom {
  padding-left: 0.5em;
  padding-right: 0.3em;
}
.flexlayout__border_toolbar_button {
  border: none;
  outline: none;
  font-size: inherit;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;
}
@media (hover: hover) {
  .flexlayout__border_toolbar_button:hover {
    background-color: var(--color-4);
  }
}
.flexlayout__border_toolbar_button_overflow {
  display: flex;
  align-items: center;
  border: none;
  color: var(--color-overflow);
  font-size: inherit;
  background-color: transparent;
}
.flexlayout__popup_menu {
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__popup_menu_item {
  padding: 2px 0.5em;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
}
@media (hover: hover) {
  .flexlayout__popup_menu_item:hover {
    background-color: var(--color-6);
  }
}
.flexlayout__popup_menu_container {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--color-popup-border);
  color: var(--color-popup-unselected);
  background: var(--color-popup-unselected-background);
  border-radius: 3px;
  position: absolute;
  z-index: 1000;
  max-height: 50%;
  min-width: 100px;
  overflow: auto;
  padding: 2px;
}
.flexlayout__floating_window _body {
  height: 100%;
}
.flexlayout__floating_window_content {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.flexlayout__floating_window_tab {
  overflow: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  background-color: var(--color-background);
  color: var(--color-text);
}
.flexlayout__error_boundary_container {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
}
.flexlayout__error_boundary_content {
  display: flex;
  align-items: center;
}
.flexlayout__tabset_sizer {
  padding-top: 5px;
  padding-bottom: 3px;
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__tabset_header_sizer {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__border_sizer {
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: var(--font-size);
  font-family: var(--font-family);
}

.empty__tab {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* FOOTER STYLES */
.fxtr-footer-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  z-index: 600;
}

/*# sourceMappingURL=dark.css.map */
