.pdf-preview {
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
}
.pdf-preview iframe {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  outline: none;
}
